<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.voucherNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ formatDate(form.payDate) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.paymentMethod }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ formatPrice(form.bankCharge) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.currency }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-5" v-if="form.banks && form.banks.length > 0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <account-list :items="form.banks" type="detail"></account-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.accounts && form.accounts.length > 0" class="my-2">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <account-table :items="form.accounts" type="detail"></account-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Kasbon <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.items"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.bank="{ item }">
                          {{ item.bank ? item.bank.number : "" }}
                        </template>
                        <template v-slot:item.totalAmount="{ item }">
                          {{ formatPrice(item.totalAmount) }}
                        </template>
                        <template v-slot:item.totalUseAmount="{ item }">
                          {{ formatPrice(item.totalUseAmount) }}
                        </template>
                        <template v-slot:item.totalRemainAmount="{ item }">
                          {{ formatPrice(item.totalRemainAmount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Used</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalUseAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Remain Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalRemainAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import AccountList from "@/components/AccountList";
import AccountTable from "@/components/AccountTable";
import moment from "moment";

export default {
  name: "detail-payment-settlement-driver-kasbon",
  components: {
    "account-list": AccountList,
    "account-table": AccountTable,
  },
  props: {
    form: Object,
    id: Number,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Kasbon Number",
        value: "kasbonNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Kasbon Name",
        value: "kasbonName",
        sortable: false,
        divider: true,
      },
      {
        text: "Division",
        value: "division",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "bank",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      // {
      //   text: "Requirement",
      //   value: "category",
      //   sortable: false,
      //   divider: true,
      // },
      // {
      //   text: "Description",
      //   value: "description",
      //   sortable: false,
      //   divider: true,
      // },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Amount",
        value: "totalAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Use Amount",
        value: "totalUseAmount",
        sortable: false,
        divider: true,
      },
      // {
      //   text: "Total Remain Amount",
      //   value: "totalRemainAmount",
      //   sortable: false,
      //   divider: true,
      // },
    ],
  }),

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    // accountNumber(id) {
    //   const number = this.listAccountNumber.find(x => x.id === id);
    //   const result = number ? number.accountNumber : "";
    //   return result;
    // },
  },
};
</script>

<style></style>
