<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Kasbon</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Kasbon Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.kasbonNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Kasbon Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.kasbonName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Division</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.division }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="item.bank">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Account Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.bank ? item.bank.number : "" }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.currency }}</span>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'settlement'">
            <v-row class="py-0" >
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Requirement</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.category }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'settlement'">
            <v-row class="py-0" >
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Description</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <span>{{ item.description }}</span>
              </v-col>
            </v-row>
          </v-col> -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="item.rate"
                  @input="onInputRate"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Total</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                {{ formatPrice(item.totalAmount) }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'settlement'">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Total Use</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                {{ formatPrice(item.totalUseAmount) }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'settlement'">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Total Remain</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                {{ formatPrice(item.totalRemainAmount) }}
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'settlement'">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="accountName"
                  return-object
                  v-model="account"
                  :items="accounts"
                  @change="onChangetAccount"
                ></v-select>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="red" text @click="deleteItem">
          Delete
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-kasbon",
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
    accounts: Array,
    type: String,
  },
  data() {
    return {
      account: null,
    };
  },
  methods: {
    save() {
      this.$emit("save", this.item);
      this.close();
    },
    close() {
      this.account = null;
      this.$emit("close");
    },
    deleteItem() {
      this.$emit("deleteItem", this.index);
      this.account = null;
    },
    onInputRate() {
      this.item.totalAmount = this.item.totalAmount * this.item.rate;
      this.item.totalUseAmount = this.item.totalUseAmount * this.item.rate;
      this.item.totalRemainAmount = this.item.totalRemainAmount * this.item.rate;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    // onChangetAccount(val) {
    //   this.item.accountId = val.id;
    //   this.item.accountName = val.accountName;
    //   this.item.accountNumber = val.accountNumber;
    // },
  },
};
</script>

<style></style>
