<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.voucherNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Form Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.formNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.payDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.payDate" @input="menuDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  :items="employees"
                  item-text="name"
                  item-value="id"
                  background-color="white"
                  v-model="form.employeeId"
                  disabled
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.paymentMethod"
                  :items="listPaymentMethod"
                  disabled
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="account"
                  :items="listBank"
                  item-text="accountName"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.banks.length > 0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <account-list :items="form.banks" @deleteItem="deleteItemAccount"></account-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.bankCharge"
                  :rules="[v => v >= 0 || 'Bank Charge must more than 0']"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        :items="listSettlementKasbon"
                        item-text="kasbonNumber"
                        return-object
                        v-model="kasbon"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on" @click.stop="addKasbon(data.item)">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.kasbonNumber"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Kasbon <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.bank="{ item }">
                          {{ item.bank ? item.bank.number : "" }}
                        </template>
                        <template v-slot:item.totalAmount="{ item }">
                          {{ formatPrice(item.totalAmount) }}
                        </template>
                        <template v-slot:item.totalUseAmount="{ item }">
                          {{ formatPrice(item.totalUseAmount) }}
                        </template>
                        <template v-slot:item.totalRemainAmount="{ item }">
                          {{ formatPrice(item.totalRemainAmount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Used</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.totalUseAmount"
                  @input="onInputTotalUseAmount"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Remain Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalRemainAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-payment-kasbon
      :item="item"
      :index="index"
      :dialog="dialog"
      @save="close"
      @deleteItem="deleteItem"
      type="settlement"
    ></dialog-payment-kasbon>
  </v-form>
</template>

<script>
import AccountList from "@/components/AccountList";
import DialogPaymentKasbon from "@/components/DialogPaymentKasbon";

export default {
  name: "create-payment-general-kasbon",
  components: {
    "dialog-payment-kasbon": DialogPaymentKasbon,
    "account-list": AccountList,
  },
  props: {
    form: Object,
    listBank: Array,
    listAccountNumber: Array,
    listSettlementKasbon: Array,
    employees: Array,
  },
  data: () => ({
    menuDate: false,
    dialog: false,
    item: {},
    index: -1,
    category: null,
    account: null,
    valid: true,
    listPayment: ["Giro", "Cash", "Transfer"],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Kasbon Name",
        value: "category",
        sortable: false,
        divider: true,
      },
      {
        text: "Requirement",
        value: "category",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
    ],
  }),

  methods: {
    update() {
      if (this.$refs.form.validate() && this.items.length > 0 && this.form.banks.length > 0) {
        this.form.totalAmount = this.totalAmount;
        this.$store.dispatch("paymentSettlementKasbon/update", this.form);
        this.clear();
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Settlement Kasbon and Account is required",
        });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.items.splice(index, 1);
        this.dialog = false;
      }
    },
    deleteItemAccount(index) {
      if (index > -1) {
        this.form.banks.splice(index, 1);
      }
    },
    close() {
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    addKasbon(item) {
      this.item = item;
      this.index = this.form.items.map(x => x).indexOf(item);
      this.dialog = true;
      this.form.items.push(item);
    },
    addAccount(item) {
      this.form.bank.push(item);
    },
    onInputTotalUseAmount() {
      this.form.totalRemainAmount = this.form.totalAmount - this.form.totalUseAmount;
    },
  },
};
</script>

<style></style>
