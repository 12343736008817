<template>
  <v-data-table
    :headers="type === 'detail' ? headersDetail : headers"
    :items="items"
    disable-pagination
    hide-default-footer
    dense
  >
    <template v-slot:item.no="{ item }">
      {{ items.map(x => x).indexOf(item) + 1 }}
    </template>
    <template v-slot:item.amount="{ item }">
      <span v-if="type === 'detail'">{{ formatPrice(item.amount) }}</span>
      <v-text-field
        v-if="type != 'detail'"
        type="number"
        outlined
        flat
        dense
        background-color="white"
        prefix="Rp."
        v-model.number="item.amount"
      ></v-text-field>
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn color="red" icon @click="deleteItem(item)" v-if="type != 'detail'">
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "account-list",
  props: {
    items: Array,
    type: String,
  },
  data() {
    return {
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Account Number",
          value: "accountNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Account Name",
          value: "accountName",
          sortable: false,
          divider: true,
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
          align: "center",
          divider: true,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
        },
      ],
      headersDetail: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Account Number",
          value: "accountNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Account Name",
          value: "accountName",
          sortable: false,
          divider: true,
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
          align: "center",
          divider: true,
        },
      ],
    };
  },
  methods: {
    deleteItem(item) {
      const index = this.items.map(x => x).indexOf(item);
      this.$emit("deleteItem", index);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
